@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@500&display=swap'); 
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap');
* {font-family: 'Noto Sans Thai', sans-serif;}
.bg {z-index: 5px;}

/************************* Point Page (Page No. 1) ****************************/
a {text-decoration: none!important;}
.godji_01 {position: absolute;width: 200px;height: auto; right: -17px; bottom: -49px; z-index: 100;}
.banner {position: absolute; width: 20%; height: auto; top: 15px; left: 15px;}
.logo {position: absolute; width: 77%; height: auto; top: 10%; left: 15%;}

.title-name {color: #00b9fe; font-size: 23px;}
.login {position: absolute; top: 39%;}
.login .form-control {font-size: 1.1rem;}
.ant-picker .ant-picker-input input {text-align: center !important; font-size: 1.1rem;}
input {background-color: #f4fffd!important;}
::placeholder {
    color: #0d5cbc!important;
    opacity: 1; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
   color: #0d5cbc;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
   color: #0d5cbc;
  }

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}



/************************* Point Page (Page No. 2) ****************************/
.logo_p2 {position: absolute; width: 100px; height: auto; top: 2%; right: 3%;}
.point {position: absolute; width: 90%; top: 79px; left: 5%;}
.title-count {font-size: 12px;}
.unit {font-size: 14px;}
.star {width: 25px;}
.counter {position: absolute; left: 25%; top: 127px; height: 65px;}
.counter h1 {font-size: 2.2rem;}
.suggest {height: 420px; border-radius: 50px 50px 0px 0px; box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);}
.suggest .img-sug {width: 73%;}
.suggest .readmore {font-size: 0.9rem;}
.monthly {width: 100%; height: 200px; background-color: #215bde; border-radius: 20px; top: 27.4%;}
.monthly h3, .campaign h3 { font-family: 'Roboto Condensed'!important; letter-spacing: 1px;}
.campaign {width: 100%; height: 200px; background-color: #00ccff; border-radius: 20px; top: 41%;}
.monthly-height {height: 150px;}

/************************* Menu-Category ****************************/
.menu-category ul {display: flex; list-style: none; justify-content:end; align-items: center; margin-top: 5px; padding: unset; }
.menu-category ul li {margin: 5px; padding: 5px 10px; border-radius: 20px; font-size: 0.8rem; border: 1px solid #215bde; color: #215bde; background-color: white;}
/* .menu-category ul li:first-child {font-size: 0.9rem; background-color: #215bde; color: white;} */
.category-active {font-size: 0.9rem !important; background-color: #215bde !important; color: white !important;}
/* .menu-category ul li:last-child img {box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);} */

/************************* Menu Main ****************************/
.menu-main {position: absolute; border-radius: 40px 40px 0 0; height: 80px; background-color: #215bde; bottom: -28px;}
/* .menu-main ul {display: flex; list-style: none; justify-content: center; align-items: center; margin-top: 5px;} */
.menu-main ul {list-style: none;  text-align: left;}
/* .menu-main ul li {margin: 10px 30px;} */
.menu-main ul li {margin: 10px 0;}
.menu-main ul li img {width: 25px;}

/************************* Product ****************************/
.wrap-product {position: relative;}
.product-list {position: absolute;}
.product-list .img {position: relative; width: 160px; height: 160px;}
.product-list .img img {width: 100%;}
.hot {position: absolute; width: 20px; top: -3px; right: 10px;}
.fontawesome {font-size: 0.7rem; padding: 2px; display: flex; justify-content: flex-end;}

/************************* Article (Page No. 3) ****************************/
.search {position: absolute; right: 10px; top: 20px;}
.wrapper-search {width: 200px; padding: 2px 0;}
.wrapper-search input {width: 80%; height: 26px; background-color: unset!important;}
.wrapper-search input:focus {outline: none;}
.search-icon {width: 20px;}
.menu-ham {width: 30px; height: 20px;}
.arrow-back {width: 30px; height: 20px;}
.title {position: absolute; width: 85%; height: auto; top: 55px; left: 20px;}

.wrap-product {position: relative;}
#article .text-art {font-size: 0.8rem; padding: 5px 0;}
#article .product-list {position: absolute;}
#article .product-list .img {position: relative; width: 170px; height: 170px;}
#article .product-list .img img {width: 100%;}
/* .img-pro { width: 150px; height: 150px;} */
.img-pro { width: 160px; height: 160px;}
.img-pro img {width: 100%; height: 100%; object-fit: cover;}
#article .hot {position: absolute; width: 20px; top: -3px; right: 10px;}
#article .fontawesome {font-size: 0.7rem; padding: 2px 0; display: flex;}

#article .menu-category-article {width: 100%;}
#article .menu-category-article ul {display: flex; justify-content: center; list-style: none; padding-left: 0px;}
#article .menu-category-article ul li {margin: 3px; padding: 0 5px; border-radius: 20px; font-size: 0.7rem; border: 2px solid #215bde; color: #215bde; background-color: white; display: flex; align-items: center;}
#article .menu-category-article ul li:first-child {font-size: 0.9rem; color: white;}
.date-icon {width: 24px;}
.product-name {font-size: 0.6rem;}
.datepicker {width: 100%; color: #0d5cbc!important;}

/************************* Carousel ****************************/
.carousel-inner {text-align: center;}
.carousel-item .img { width: 15%; height: 52px; margin: 0 auto;}
.carousel-item .img img {width: 100%; height: 100%; background-color: brown; object-fit: cover;}
.carousel-indicators {margin-bottom: unset!important; bottom: -10px!important;}
.carousel-indicators button {width: 5px!important; height: 5px!important; border-bottom: 0!important; border: 1px solid white!important; border-radius: 15px;}
.carousel-caption {top: 0!important; padding-top: unset!important;}
.carousel-caption h3 {font-size: 15px;}
.carousel-control-prev , .carousel-control-next {display: none!important;}

/************************* Scroll ****************************/
.wrapper-product {overflow-y: scroll; overflow-x: hidden; height: 80%;}
#product {overflow-y: scroll; overflow-x: hidden; }

/************************* Questions (Page No. 4) ****************************/
.godji {position: absolute; width: 207px; height: auto; right: -10px; bottom: -40px; z-index: 100;}
.article-sky {position: absolute; width: 89%; height: auto; top: 30%; left: 0; right: 0; bottom: 0; margin: 0 auto;}
.text-godji {position: absolute; width: 55%; height: auto; top: 9%; left: 0; right: 0; bottom: 0; margin: 0 auto;}
.wrapper-sky {position: absolute; top: 42%; left: 0;}
.wrapper-sky .col-6:nth-child(odd) {display: flex; justify-content: end; padding-right: 3px; margin: 3px 0;}
.wrapper-sky .col-6:nth-child(even) {display: flex; justify-content: start; padding-left: 3px; margin: 3px 0;}
.wrapper-sky .img {width: 145px; height: 105px;}
.wrapper-sky .img img {width: 100%; height: 100%; object-fit: cover; border-radius: 30px; border: 3px solid white;box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);}
.wrapper-sky .next {width: 13px; margin-left: 10px;}
.wrapper-sky .send {width: 50px;}
.button-send {margin-left: 50px;}

/************************* Content Detail (Page No. 5) ****************************/
/* */
.image-gallery-fb div {border-radius: 50px 50px 0 0 !important; }
/* .css-bhp9pd-MuiPaper-root-MuiCard-root {border-radius: 50px 50px 0 0 !important; } */
.bug {position: absolute; width: 60px; top: 46px; right: 62px;}
#content {bottom: 0; height: 87%; border-radius: 50px 50px 0 0; box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);}

/* #content {position: absolute; width: 94%; bottom: 0; height: 87%; left: 3%; border-radius: 50px 50px 0 0; box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);} */
#content .img-detail {width: 300px; height: 300px; margin: 0 auto;}
#content .img-detail img {width: 100%; height: 100%; object-fit: cover;}
#content .imgDetail {width: 90%; margin: 0 auto;}
#content h2 {font-family: 'Roboto Condensed'!important; letter-spacing: 1px;}
#content .img {width: 110px; height: 110px; margin: 0 auto;}
#content .img img {width: 100%; height: 100%;}




.bg-home {
  /* The image used */

  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 400px) {
  #article .menu-category-article ul li {margin: unset;}
}
@media (max-width: 391px) {
  .counter {top: 122px;}
  .button-send {margin-left: 30px;}
}
@media (max-width: 380px) {
  .counter {top: 116px;}
  #article .menu-category-article ul li:first-child {font-size: 0.8rem;}
  .date-icon {width: 20px;}
  .button-send {margin-left: 25px;}
}

.menu-category-article .ant-picker .ant-picker-input input {
  height: 17px;
  font-size: 9px;
}

:where(.css-dev-only-do-not-override-mxhywb).ant-picker .ant-picker-suffix {
  color: #0d5cbc!important;
}

.menu-category-article :where(.css-dev-only-do-not-override-mxhywb).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  font-size: 9px;
  height: 17px;
}

/* .menu-category-article :where(.css-dev-only-do-not-override-mxhywb).ant-select-single .ant-select-selector .ant-select-selection-item, :where(.css-dev-only-do-not-override-mxhywb).ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 17px;
} */

.menu-category-article ul li div div span {
  line-height: 17px !important;
}

.wrapper-sky .img .active {
  border: 4px solid #215bde;
}

/*************************************************************/
.boximgHome{
  margin-left: 6%!important;
}
.SubboximgHome{
  margin:8px!important;
}
.CardMemberTopMargin{
  margin-top: 3%!important;
}
.boxContantall{
  margin-left: 4.5%!important;
}

.group-ranking {
  position: absolute;
  margin-top: 190px;
  width: 75%;
  height: 61%;
  overflow-x: hidden;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  border-radius: 10px;
}
.name{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid #fff;
  position: relative;
  overflow: hidden;
  background-color: #b5e6f2;
}
.wrapper {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 3px solid #fff;
  background-color: #b5e6f2;
  position: relative;
  overflow: hidden;
}
.wrapper p {
  position: absolute;
  top: 100px;
  background-color: aquamarine;
}
.profile-pic {
  width: 100%;
}
.linkTitleShare {
  padding-top: 15px;
}
.click-game img {width: 160px;}
.content-rules {
  position: absolute;
  background-color: transparent;
  /* max-width: 80%; */
  max-height: 60%;
  margin-top: 100px;
  /* border-radius: 10px; */
  overflow-x: hidden;
  overflow-y: scroll;
  /* scroll-snap-type: y mandatory; */
  /* padding: 10px; */
  color: white;
}
.ant-select-selection-item { font-size: 1rem;}
.ant-picker-input input {background-color: white!important; font-size: 1rem!important;}

/*************************************************************/
@media (max-width: 420px) {
  .group-ranking {width: 70%;}
}
@media (max-width: 415px) {
  .wrap-profile {padding-top: 20px;}
  /* .suggest {height: 580px;} */
}
@media (max-width: 395px) {
  .group-ranking {width: 75%;}

}
@media (max-width: 388px) {
  .group-ranking {width: 80%;}
  .wrap-profile {padding-top: unset;}
  .boximgHome{
    margin-left: 1%!important;
  }
  .SubboximgHome{
    margin:4.5%!important;
  }
  .CardMemberTopMargin{
    margin-top: 0%!important;
  }
  .boxContantall{
    margin-left: 0%!important;
  }
}
@media (max-width: 330px) {
  .group-ranking {width: 90%;}
  .wrapper {width: 100px; height: 100px;}
  .click-game img {width: 130px;}
}



.popup-content{
  background-color: transparent!important;
  /* background-image: url("/./public/assets/images/ranking/rules.png")!important; */
  border:0!important;
  padding:0!important;
  box-shadow: unset!important;
  width: 100%!important;
  left:0%!important;
}

.rules{
  width:340px;
  height:370px;
  background-image:url("/./assets/images/ranking/rules_1.png");
  background-repeat: no-repeat;
  background-size: 99%;
}
.col-6{
  width :40%!important;
}

  .sizeBG{
    width: 60%;
  }
  .sizeImg{
    width: 50%;
    }
  @media (max-width: 440px) {
    .sizeImg{
      width: 100%;
      }
      .sizeBG{
          width: 100%;
      }
  }

  @media screen and (min-width: 820.1px) and (max-width: 920.0px) {
    .counter {top: 250px;}
    .monthly {height: 325px;}
    .campaign {height: 325px;}
    .monthly-height {height: 270px;}
    .boximgHome{
      margin-left: 1%!important;
    }
    .SubboximgHome{
      margin: 4.5%!important;
    }
    .suggest {height: 820px;}
    .product-list .img {width: 300px; height: 320px;}
    .img-pro {width: 300px; height: 320px;}
  }

  @media screen and (min-width: 768.1px) and (max-width: 820.0px) {
    .counter {top: 235px;}
    .monthly {height: 325px;}
    .campaign {height: 325px;}
    .monthly-height {height: 270px;}
    .boximgHome{
      margin-left: 1%!important;
    }
    .SubboximgHome{
      margin: 4.5%!important;
    }
    .suggest {height: 820px;}
    .product-list .img {width: 300px; height: 320px;}
    .img-pro {width: 300px; height: 320px;}
  }

  @media screen and (min-width: 540.1px) and (max-width: 768.0px) {
    .counter {top: 235px;}
    .campaign{height: 325px;}
    .monthly {height: 325px;}
    .monthly-height {height: 270px;}
    .boximgHome{
      margin-left: 1%!important;
    }
    .SubboximgHome{
      margin: 4.5%!important;
    }
    .suggest {height: 820px;}
    .product-list .img {width: 300px; height: 320px;}
    .img-pro {width: 300px; height: 320px;}
  }

  @media screen and (min-width: 440.1px) and (max-width: 540.0px){
    .counter {top: 175px;}
    .campaign {height: 260px;}
    .monthly {height: 260px;}
    .monthly-height {height: 200px;}
    .boximgHome{
      margin-left: 2%!important;
    }
    .SubboximgHome{
      margin: 4%!important;
    }
    .suggest {height: 700px;}
    .product-list .img {width: 230px; height: 250px;}
    .img-pro {width: 230px; height: 250px;}
    .space {padding-top: 15px;}
  }