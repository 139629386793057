.img_member {
    position: absolute;
    width:  97px;
    height: 97px;
    border-radius: 50%;
    left: -76px;
    top: -40px;
}

.username {
    position: absolute;
    top: -50%;
    left: 15%;
    margin: 0px;
    width: 85%;
    height: 50%;
    color: blue;
    font-size: 1.1rem;
    display: flex;
    align-items: end;
}

@media screen and (min-width: 820.1px) and (max-width: 920.0px){
    .img_member {
        width: 200px;
        height: 200px;
        left: -114px;
        top: -88px;
    }
    .username {top: -70%; left: 22%;}
}

@media screen and (min-width: 768.1px) and (max-width: 820.0px){
    .img_member {
        width: 200px;
        height: 200px;
        left: -137px;
        top: -87px;
    }
    .username {top: -70%; left: 20%;}
}

@media screen and (min-width: 540.1px) and (max-width: 768.0px){
    .img_member {
        width: 200px;
        height: 200px;
        left: -150px;
        top: -95px;
    }
    .username {top: -80%;}
}

@media screen and (min-width: 440.1px) and (max-width: 540.0px){
    .img_member {
        width: 147px;
        height: 147px;
        left: -117px;
        top: -70px;
    }
    .username {top: -70%;}
}


@media screen and (min-width: 433.1px) and (max-width: 440.0px) {
    .img_member {
        width: 119px;
        height: 119px;
        left: -95px;
        top: -38px;
    }
}

@media screen and (min-width: 424.1px) and (max-width: 433.0px) {
    .img_member {
        width: 117px;
        height: 117px;
        left: -92px;
        top: -40px;
    }
}

@media screen and (min-width: 414.1px) and (max-width: 424.0px) {
    .img_member {
        width: 115px;
        height: 115px;
        left: -90px;
        top: -42px;
    }
}

@media screen and (min-width: 396.1px) and (max-width: 414.0px) {
    .img_member {
        width: 112px;
        height: 112px;
        left: -88px;
        top: -42px;
    }
}

@media screen and (min-width: 390.1px) and (max-width: 396.0px) {
    .img_member {
        width: 107px;
        height: 107px;
        left: -83px;
        top: -43px;
    }
}

@media screen and (min-width: 379.1px) and (max-width: 390.0px) {
    .img_member {
        width: 105px;
        height: 105px;
        left: -82px;
        top: -41px;
    }
}

@media screen and (min-width: 370.1px) and (max-width: 379.0px) {
    .img_member {
        width: 100px;
        height: 100px;
        left: -78px;
        top: -37px;
    }
}

@media screen and (min-width: 350.1px) and (max-width: 370.0px) {
    .img_member {
        width: 97px;
        height: 97px;
        left: -75px;
        top: -40px;
    }
}

@media screen and (min-width: 330.1px) and (max-width: 350.0px) {
    .img_member {
        width: 88px;
        height: 88px;
        left: -68px;
        top: -43px;
    }
    .username {
        top: -70%;
    }
}

@media screen and (min-width: 320.1px) and (max-width: 330.0px) {
    .img_member {
        width: 82px;
        height: 82px;
        left: -64px;
        top: -44px;
    }
    .username {
        top: -87%;
    }
}

@media screen and (min-width: 310.1px) and (max-width: 320.0px) {
    .img_member {
        width: 85px;
        height: 85px;
        left: -66px;
        top: -46px;
    }
    .username {
        top: -87%;
    }
}

@media screen and (min-width: 294.1px) and (max-width: 310.0px) {
    .img_member {
        width: 80px;
        height: 80px;
        left: -62px;
        top: -47px;
    }
    .username {
        top: -87%;
    }
}

@media screen and (max-width: 294.0px) {
    .img_member {
        width: 77px;
        height: 77px;
        left: -59px;
        top: -50px;
    }
    .username {
        top: -70%;
    }
}